/* global module, require, process */

const { default: getConfig } = require("next/config");

const config = getConfig();

const DISABLE_BOOKING =
  config != null
    ? config.publicRuntimeConfig.DISABLE_BOOKING
    : typeof process !== "undefined"
    ? process.env.DISABLE_BOOKING === "true"
    : false;

// A `.sitemap` function that results in zero sitemap URLs.

const ROUTES = {
  status: {
    pathname: "/status",
    hideFromSitemap: true,
  },

  home: {
    pathname: "/",
  },

  booking: {
    pathname: "/boka-resa",
    hide: DISABLE_BOOKING,
  },

  bookingDone: {
    pathname: "/bokning/[sessionId]",
    hideFromSitemap: true,
    hide: DISABLE_BOOKING,
  },

  checkout: {
    pathname: "/checkout/[slug]",
    hideFromSitemap: true,
    hide: DISABLE_BOOKING,
  },

  terms: {
    pathname: "/biljetter-och-villkor",
  },

  support: {
    pathname: "/kundservice",
  },

  supportPage: {
    pathname: "/kundservice/[slug]",
    hideFromSitemap: true,
  },

  cafeMenu: {
    pathname: "/cafe-meny",
  },

  pressAndMedia: {
    pathname: "/press-och-media",
  },

  workWithUs: {
    pathname: "/jobba-med-oss",
  },

  silverRail: {
    pathname: "/silverrail",
  },

  account: {
    pathname: "/konto",
    requiresLogin: true,
    hideFromSitemap: true,
  },

  signup: {
    pathname: "/konto/registrera",
  },

  login: {
    pathname: "/konto/logga-in",
  },

  loginCredentials: {
    pathname: "/konto/logga-in-uppgifter",
    hideFromSitemap: true,
  },

  loginCredentialsCorporate: {
    pathname: "/konto/logga-in-foretag",
    hideFromSitemap: true,
  },

  resetPassword: {
    pathname: "/konto/aterstall-losenord",
  },

  settings: {
    pathname: "/konto/installningar",
    requiresLogin: true,
    hideFromSitemap: true,
  },

  myBenefits: {
    pathname: "/konto/mina-formaner",
    requiresLogin: true,
    hideFromSitemap: true,
  },

  myBonus: {
    pathname: "/konto/min-bonus",
    requiresLogin: true,
    hideFromSitemap: true,
  },

  manageBooking: {
    pathname: "/konto/hantera-bokning/[id]",
    requiresLogin: true,
    hideFromSitemap: true,
  },

  allTrips: {
    pathname: "/konto/alla-resor",
    requiresLogin: true,
    hideFromSitemap: true,
  },

  changeEmail: {
    pathname: "/konto/byt-email",
    hideFromSitemap: true,
  },

  sthlmToGbg: {
    pathname: "/stockholm-till-goteborg",
  },

  gbgToSthlm: {
    pathname: "/goteborg-till-stockholm",
  },

  gbgToSkovde: {
    pathname: "/goteborg-till-skovde",
  },

  travelFromSthlm: {
    pathname: "/res-fran-stockholm",
  },

  travelToGbg: {
    pathname: "/res-fran-goteborg",
  },

  trainOverPlane: {
    pathname: "/tag-istallet-for-flyg",
  },

  trainOverBus: {
    pathname: "/tag-istallet-for-buss",
  },

  highspeedTrain: {
    pathname: "/snabbtag",
  },

  becomeOneOfUs: {
    pathname: "/bli-en-av-oss",
  },

  weAreVR: {
    pathname: "/vi-ar-vr",
  },

  benefits: {
    pathname: "/formaner",
  },

  culture: {
    pathname: "/kultur-och-varderingar",
  },

  applicationProcess: {
    pathname: "/vanliga-fragor-om-ansokningsprocessen",
  },

  diversity: {
    pathname: "/mangfald-och-inkludering",
  },

  applyForJob: {
    pathname: "/att-soka-jobb-hos-vr",
  },

  aboutUs: {
    pathname: "/om-oss",
  },

  substainability: {
    pathname: "/hallbarhet",
  },

  punctuality: {
    pathname: "/punktlighet",
  },

  satisfaction: {
    pathname: "/nojdast-kunder",
  },

  travelConditions: {
    pathname: "/allmanna-resevillkor",
  },

  buyConditions: {
    pathname: "/kopvillkor",
  },

  euRights: {
    pathname: "/EU-rattigheter",
  },

  discrimination: {
    pathname: "/icke-diskriminerande-regler",
  },

  integration: {
    pathname: "/integritetspolicy",
  },

  clubXterms: {
    pathname: "/allmanna-villkor-clubx",
  },

  myVRterms: {
    pathname: "/allmanna-villkor-mittvr",
  },

  wifiTerms: {
    pathname: "/anvandarvillkor-wifi",
  },

  cookies: {
    pathname: "/cookies",
  },

  ourDestinations: {
    pathname: "/vara-destinationer",
  },

  toSthlm: {
    pathname: "/tag-till-stockholm",
  },

  toSodertalje: {
    pathname: "/tag-till-sodertalje",
  },

  toSkovde: {
    pathname: "/tag-till-skovde",
  },

  toBoras: {
    pathname: "/res-till-boras",
  },

  toHerrljunga: {
    pathname: "/tag-till-herrljunga",
  },

  toAllingsas: {
    pathname: "/tag-till-alingsas",
  },

  toSolna: {
    pathname: "/tag-till-solna",
  },

  toGbg: {
    pathname: "/tag-till-goteborg",
  },

  toVarberg: {
    pathname: "/tag-till-varberg",
  },

  toFalkenberg: {
    pathname: "/tag-till-falkenberg",
  },

  toHalmstad: {
    pathname: "/tag-till-halmstad",
  },

  toLaholm: {
    pathname: "/tag-till-laholm",
  },

  toBastad: {
    pathname: "/tag-till-bastad",
  },

  timetable: {
    pathname: "/tidtabell-trafikinformation",
  },

  maintainence: {
    pathname: "/banarbete",
  },

  ourExpressTrains: {
    pathname: "/vara-snabbtag",
  },

  onboardAdvertising: {
    pathname: "/annonsera-ombord",
  },

  groupTravel: {
    pathname: "/gruppresor",
  },

  travelSmart: {
    pathname: "/res-smart-med-vr",
  },

  clubX: {
    pathname: "/clubx",
  },

  myVR: {
    pathname: "/mittvr",
  },

  rebranding: {
    pathname: "/mtrexpress-blir-mtrx",
  },

  fiveYearPlan: {
    hide: true,
    pathname: "/kampanj/mtrx-fem-ar",
    hideFromSitemap: true,
  },

  childrensDiscount: {
    pathname: "/kampanj/barnrabatt",
  },

  blackFriday: {
    hide: true,
    pathname: "/kampanj/black-friday",
    hideFromSitemap: true,
  },

  midDaySales: {
    hide: true,
    pathname: "/kampanj/mellandags-rea",
    hideFromSitemap: true,
  },

  ninetyninekr: {
    hide: true,
    pathname: "/kampanj/99-kr-kampanj",
    hideFromSitemap: true,
  },

  customerService: {
    pathname: "/kundservice",
  },

  commuterCard: {
    pathname: "/pendlarkort",
  },

  commuterCardSelected: {
    pathname: "/pendlarkort/[id]",
    hideFromSitemap: true,
  },

  receiptCommuterCard: {
    pathname: "/kvitto-pendlarkort/[id]",
    hideFromSitemap: true,
  },

  b2b: {
    pathname: "/konto/vr-for-foretag",
    requiresLogin: true,
    hideFromSitemap: true,
  },

  corporateAgreement: {
    pathname: "/konto/vr-for-foretag/foretagsavtal/[id]",
    requiresLogin: true,
    hideFromSitemap: true,
  },

  corporates: {
    pathname: "/konto/mina-avtal",
    requiresLogin: true,
    hideFromSitemap: true,
  },

  corporate: {
    pathname: "/konto/mina-avtal/avtal/[id]",
    requiresLogin: true,
    hideFromSitemap: true,
  },

  corporateStatistics: {
    pathname: "/konto/mina-avtal/statistik",
    requiresLogin: true,
    hideFromSitemap: true,
  },

  interviewDriver: {
    pathname: "/intervju-lokforare",
  },

  interviewService: {
    pathname: "/intervju-servicevard",
  },

  interviewOperationsService: {
    pathname: "/intervju-service-och-driftledare",
  },

  interviewHeadOfPlanning: {
    pathname: "/intervju-head-of-planning",
  },

  myTrip: {
    pathname: "/min-resa/[sessionId]",
    hideFromSitemap: true,
  },

  myTripFind: {
    pathname: "/min-resa",
  },

  termsCommuter: {
    pathname: "/kopvillkor-pendlarkort",
  },

  adminHome: {
    pathname: "/_/general",
    hide: true,
    hideFromSitemap: true,
  },

  adminPages: {
    pathname: "/_/general/[...catchAll]",
    hide: true,
    hideFromSitemap: true,
  },

  orderReceipt: {
    pathname: "/order-kvitto/[orderId]",
    hide: true,
    hideFromSitemap: true,
  },

  studentDiscount: {
    pathname: "/studentrabatt",
  },

  seniorDiscount: {
    pathname: "/pensionarsrabatt",
  },
};

const EXCLUDE_FROM_SITEMAP = [
  "/_/**",
  "/min-resa/**",
  "/kvitto-pendlarkort/**",
  "/konto**",
  "/checkout/**",
  "/bokning/**",
  "/status",
];

module.exports = {
  ROUTES,
  EXCLUDE_FROM_SITEMAP,
};
